import React, { Suspense, lazy, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

const AppMain = () => {
  return (
    <Fragment>
      {/* Dashboards */}
      <Suspense fallback={<div className="loading"></div>}>
        <Route path="/dashboards" component={Dashboards} />
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/dashboards/lecturer" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
