import React from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./assets/base.css";
import Main from "./DemoPages/Main";
import Login from "./DemoPages/Login";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";


const store = configureStore();
const rootElement = document.getElementById("root");
const storedData = localStorage.getItem("data");
const data = JSON.parse(storedData);

let isAuthenticated = "";
if (data && data.isLogin !== null) {
  isAuthenticated = true;
} else {
  isAuthenticated = false;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Main} />
      </Switch>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<App />, rootElement);

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    ReactDOM.render(<App />, rootElement);
  });
}

unregister();
//registerServiceWorker();
