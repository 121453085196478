
/*
APP API
*/

export const apiUrl = process.env.REACT_APP_APIPATH;
export const ethUrlAdd = process.env.REACT_APP_ETH_URL_ADD;
export const ethUrlDisplay = process.env.REACT_APP_ETH_URL_DISPLAY;
export const GoogleclientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// -- data of locastorage;
export const localstoredata = JSON.parse(localStorage.getItem("data"));

// -- Capitalized case;
export function capitalizeFirstLetter(string) {
    return string.toLowerCase().replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
  }
  
  