import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { apiUrl, GoogleclientID } from "../../config/defaultValues";
import { useMediaQuery } from "react-responsive";
import GoogleLogin from "react-google-login";
import bgImage from "../../assets/demo-ui/images/bg-login.jpeg";
import { toast, Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clientID = GoogleclientID;
const failSignin = () => {
  toast.error(
    "Invalid Email Address! Please contact IT Department for more information",
    {
      transition: Bounce,
      closeButton: true,
      autoClose: 4000,
      position: "top-right",
    }
  );
};

const Login = () => {
  const responseGoogle = (response) => {
    if (!response.error) {
      const userInfo = {
        email: response.profileObj.email,
        access_token: response.tokenId,
        fullname: "",
      };

      var formdata = new FormData();
      formdata.append("email", userInfo.email);
      formdata.append("access_token", userInfo.access_token);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      // Send request to your current /gmailsignin endpoint
      fetch(`${apiUrl}/gmailsignin`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          // Handle the response from /gmailsignin as needed
          fetch(`${apiUrl}/your-webhook-endpoint`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userInfo.email,
              access_token: userInfo.access_token,
              // Add more fields if needed
            }),
          })
            .then((webhookRes) => webhookRes.json())
            .then((webhookData) => {
              // Handle the response from the webhook as needed
            })
            .catch((webhookError) => {
              console.error("Webhook Error:", webhookError);
            });
            
            console.log(data);

          let data_arr = {
            access_token: data.access_token,
            isLogin: true,
            teachername: data.teacher_name,
            teacherphoto: data.teacher_photo,
          };
          const jsonString = JSON.stringify(data_arr);
          localStorage.setItem("data", jsonString);
          window.location.replace("/");
        })
        .catch((error) => {
          console.error("Error: ", error);
          failSignin();
        });
    } else {
      localStorage.clear();
      failSignin();
    }
  };

  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const loginButtonStyle = {
    backgroundColor: "rgb(66, 133, 244)",
    alignItems: "center",
    color: "rgb(255, 255, 255)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    padding: "10px 10px",
    borderRadius: "50px",
    border: "1px solid transparent",
    fontSize: "13px",
    fontWeight: "bolder",
    fontFamily: "Roboto, sans-serif",
    width: isSmallScreen ? "100%" : "500px",
    maxWidth: "100%",
    marginBottom: "10px",
  };

  return (
    <Fragment>
      <main
        style={{
          background: `url(${bgImage}) no-repeat 10% fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <div className="container">
          <Row
            style={{
              paddingTop: isSmallScreen ? "150px" : "590px",
              width: "100%",
              height: "100%",
              border: 1,
            }}
          >
            <Col md="12" lg="12" className="mb-3">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column", // Adjust layout for small screens
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <GoogleLogin
                    clientId={clientID}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        style={loginButtonStyle}
                      >
                        <svg
                          width="18"
                          height="18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="#000">
                            <path
                              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                              fill="#EA4335"
                            ></path>
                            <path
                              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                              fill="#4285F4"
                            ></path>
                            <path
                              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                              fill="#FBBC05"
                            ></path>
                            <path
                              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                              fill="#34A853"
                            ></path>
                            <path fill="none" d="M0 0h18v18H0z"></path>
                          </g>
                        </svg>
                        &nbsp;Sign in with CamEd Mail
                      </button>
                    )}
                    buttonText="Sign in with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                  />
                </div>
                <div style={{ marginTop: isSmallScreen ? "20px" : "0" }}>
                  <center>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#FFF",
                        textShadow: "2px 2px 4px #a1a1a1",
                      }}
                    >
                      Welcome to the CamEd Lecturer Portal. Please sign in to
                      commence your session!
                    </span>
                  </center>
                </div>
              </div>
              <ToastContainer />
            </Col>
          </Row>
        </div>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, error } = "";
  return { user, loading, error };
};

export default connect(mapStateToProps, {})(Login);
